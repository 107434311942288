<script lang="ts" setup></script>
<template>
  <div class="down-line">
    <div style="margin-bottom: 0.1rem">
      南京市雨花台区古雄街道新林大道7号金城华府501-284号
    </div>
    <div style="margin-bottom: 0.1rem">版权所有@南京探宠科技有限公司</div>
    <a href="https://beian.miit.gov.cn/" target="_blank"
      >苏ICP备2022036914号-1</a
    >
  </div>
</template>
<style scoped>
.down-line {
  margin-top: 2rem;
  width: 100%;
  color: #fff;
  font-size: 0.8rem;
  background-color: #212121;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

a {
  color: #fff;
  font-size: 0.6rem;
}
</style>

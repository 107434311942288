import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "vant/lib/index.css";

import {
  Image as VanImage,
  Icon,
  Lazyload,
  Button,
  Popup,
  Swipe,
  SwipeItem,
} from "vant";

const app = createApp(App);

app.use(Swipe);
app.use(SwipeItem);
app.use(VanImage);
app.use(Icon);
app.use(Button);
app.use(Lazyload, {
  lazyComponent: true,
});
app.use(Popup);

app.use(router);

app.mount("#app");
